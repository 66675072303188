@import "vars"
  
#project-info-container 
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  padding: 5rem
  z-index: 3
  display: none

  @media (max-width: $md)
    padding: 0 $mobile-wrapper-padding
    position: relative

  #scroll-button 
    @media (max-width: $md)
      display: none

  &.visible
    display: block

  .caption
    font-size: 1.5rem

    @media (max-width: $md)
      font-size: 2rem
      font-weight: bold

  .summary
    display: flex
    margin-bottom: 5rem
    gap: 4.5rem

    &__img
      position: relative

    @media (max-width: $md)
      display: flex
      flex-direction: column-reverse

    .arrow-button
      transform: rotate(90deg)

    &__text
      display: flex
      flex-direction: column

      flex-grow: 1
      gap: 3rem

.current-project
  width: 100%
  //overflow-y: auto

  &__long-info
    display: grid
    grid-template-columns: repeat(2, 1fr)
    column-gap: 4.5rem
    row-gap: 3rem

    @media (max-width: $md)
      display: flex
      flex-direction: column
      row-gap: 1.5rem

  &__section
    display: flex
    flex-direction: column
    gap: 1rem

  &__img
    object-fit: cover
    min-height: 200px
    max-width: 100%

  &__info-row
    display: flex
    gap: 5rem

    > *
      flex-grow: 1

/* new */

.project-template
  padding-bottom: 50px

  .red 
    color: $red

  @media (max-width: $md)
    padding-bottom: 0

  .body-text
    @media (min-width: $md)
      font-size: 1.5rem
      line-height: 1.5

    @media (max-width: $md)
      font-size: 1rem

.project-template__imgs
  display: flex
  gap: 52px
  margin-bottom: 2rem
  overflow-x: hidden

  @media (max-width: $md)
    flex-direction: column

.project-template__img
  flex-grow: 1
  height: 279px
  width: 100%
  object-fit: cover
  object-position: left top
  background-color: $info-block-color

  @media (max-width: $md)
    height: auto
    width: 100%

.info__header
  font-weight: 500
  font-family: "Futura PT"
  font-size: 2.2rem
  margin-bottom: 1.5rem

.info__lists
  display: flex
  gap: 6rem

  @media (max-width: $md)
    flex-direction: column
    gap: 0

.info__list 
  width: 50%
  flex-shrink: 1

  @media (max-width: $md)
    width: 100%

  ul
    list-style-type: none
    padding-left: 2rem

    @media (max-width: $md)
      margin-bottom: 2rem

    li
      position: relative
      
      .body-text
        @media (min-width: $md)
          font-size: 1.7rem

      &:not(:last-of-type)
        margin-bottom: 3rem

        @media (max-width: $md)
          margin-bottom: 2rem

      &::before
        content: ""
        border-radius: 50%
        background-color: #b3b3b3
        width: .5rem
        height: .5rem
        font-weight: bold
        position: absolute
        top: .75rem
        left: -2rem
        


.project-link-box
  margin-top: 3rem
  display: flex

  .outlined-button
    height: 3rem
    margin-right: 1rem

  .body-text
    @media (min-width: $md)
      line-height: 1
      font-size: 1.4rem







